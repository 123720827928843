import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 40,
  },
  path: {
    fill: '#C9ED21',
  },
});

export const AlithyaLogoIcon = (props: any | undefined) => {
  const defaultClasses = useStyles();
  const classes = {
    ...defaultClasses,
    ...props?.classes,
  };

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 29 29"
    >
      <path
        d="M15.352,0L4.527,23.15L0,23.15L10.825,0L15.352,0Z"
        className={classes.path}
      />
    </svg>
  );
};
