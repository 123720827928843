import React, { PropsWithChildren } from 'react';
import { Link, Theme, makeStyles } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
import MapIcon from '@material-ui/icons/MyLocation';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import { NavLink } from 'react-router-dom';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  SidebarPage,
  sidebarConfig,
  SidebarItem,
  SidebarDivider,
  SidebarSpace,
  SidebarGroup,
  useSidebarOpenState,
  SidebarScrollWrapper,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import CategoryIcon from '@material-ui/icons/Category';
import BuildIcon from '@material-ui/icons/Build';
import CodeIcon from '@material-ui/icons/Code';
import { GraphiQLIcon } from '@backstage-community/plugin-graphiql';
import LayersIcon from '@material-ui/icons/Layers';
import GroupIcon from '@material-ui/icons/People';
import { InfraWalletIcon } from '@electrolux-oss/plugin-infrawallet';
import MoneyIcon from '@material-ui/icons/MonetizationOn';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';

import { appThemeApiRef, useApi } from '@backstage/core-plugin-api';
import { ApertureLogoFull } from './ApertureLogoFull';
import { ApertureLogoIcon } from './ApertureLogoIcon';
import { MyGroupsSidebarItem } from '@backstage/plugin-org';
import { AlithyaLogoFull } from './AlithyaLogoFull';
import { AlithyaLogoIcon } from './AlithyaLogoIcon';
import { BenevaLogoFull } from './BenevaLogoFull';
import { BenevaLogoIcon } from './BenevaLogoIcon';

import { RequirePermission } from '@backstage/plugin-permission-react';
import { devToolsInfoReadPermission } from '@backstage/plugin-devtools-common';
import { timeSaverPermission } from '@tduniec/backstage-plugin-time-saver-common';
import Timelapse from '@material-ui/icons/Timelapse';

import { SidebarRagModal } from '@alithya-oss/plugin-rag-ai';
import { NotificationsSidebarItem } from '@backstage/plugin-notifications';
import { Administration } from '@janus-idp/backstage-plugin-rbac';

const useSidebarLogoStyles = makeStyles<Theme, { themeId: string }>({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: props => ({
    width: sidebarConfig.drawerWidthClosed,
    marginLeft:
      props.themeId === 'beneva-light' || props.themeId === 'beneva-dark'
        ? 0
        : 24,
  }),
  svg: {
    width: 'auto',
    height: '40px',
  },
  path: {
    fill: '#FFFFFF',
  },
});

const SidebarLogo = () => {
  const { isOpen } = useSidebarOpenState();

  const appThemeApi = useApi(appThemeApiRef);
  const themeId = appThemeApi.getActiveThemeId();
  const classes = useSidebarLogoStyles({ themeId: themeId! });

  let themeFullLogo = <LogoFull />;
  let themeIconLogo = <LogoIcon />;

  if (themeId === 'alithya') {
    themeFullLogo = <AlithyaLogoFull />;
    themeIconLogo = <AlithyaLogoIcon />;
  }

  if (themeId === 'aperture') {
    themeFullLogo = <ApertureLogoFull />;
    themeIconLogo = <ApertureLogoIcon />;
  }

  if (themeId === 'beneva-light' || themeId === 'beneva-dark') {
    themeFullLogo = <BenevaLogoFull classes={classes} />;
    themeIconLogo = <BenevaLogoIcon classes={classes} />;
  }

  if (themeId === 'dracula') {
    themeFullLogo = <LogoFull classes={classes} />;
    themeIconLogo = <LogoIcon classes={classes} />;
  }

  const fullLogo = themeFullLogo;
  const iconLogo = themeIconLogo;

  return (
    <div className={classes.root}>
      <Link
        component={NavLink}
        to="/"
        underline="none"
        className={classes.link}
      >
        {isOpen ? fullLogo : iconLogo}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
        <SidebarRagModal />
      </SidebarGroup>
      <SidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        {/* Global nav, not org-specific */}
        <SidebarItem icon={HomeIcon} to="/" text="Home" />
        <MyGroupsSidebarItem
          singularTitle="My Group"
          pluralTitle="My Groups"
          icon={GroupIcon}
        />
        <SidebarItem icon={CategoryIcon} to="catalog" text="Catalog" />
        <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" />
        <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
        <SidebarItem icon={CreateComponentIcon} to="create" text="Create..." />
        <SidebarItem icon={LayersIcon} to="explore" text="Explore" />
        {/* End global nav */}
        <SidebarDivider />
        <SidebarScrollWrapper>
          <SidebarItem
            icon={EmojiObjectsIcon}
            to="tech-insights"
            text="Tech insight"
          />
          <SidebarItem
            icon={AccessibilityIcon}
            to="lighthouse"
            text="Lighthouse"
          />
          <SidebarItem icon={MapIcon} to="tech-radar" text="Tech Radar" />
        </SidebarScrollWrapper>
        <SidebarDivider />
        <SidebarScrollWrapper>
          <SidebarItem icon={CodeIcon} to="toolbox" text="ToolBox" />
          <SidebarItem icon={GraphiQLIcon} to="graphiql" text="GraphiQL" />
          <SidebarItem
            icon={InfraWalletIcon}
            to="infrawallet"
            text="InfraWallet"
          />
          <SidebarItem
            icon={MoneyIcon}
            to="cost-insights"
            text="Cost Insights"
          />
        </SidebarScrollWrapper>
      </SidebarGroup>
      <SidebarSpace />
      <SidebarDivider />
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <NotificationsSidebarItem />
        <SidebarSettings />
        <RequirePermission permission={timeSaverPermission} errorPage={<></>}>
          <SidebarItem icon={Timelapse} to="time-saver" text="TimeSaver" />
        </RequirePermission>
        <RequirePermission
          permission={devToolsInfoReadPermission}
          errorPage={<></>}
        >
          <SidebarItem icon={BuildIcon} to="devtools" text="DevTools" />
          <Administration />
        </RequirePermission>
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
);
