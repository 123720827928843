import { Content, Page, Header } from '@backstage/core-components';
import {
  HomePageCompanyLogo,
  HomePageStarredEntities,
  HomePageTopVisited,
  HomePageRecentlyVisited,
  TemplateBackstageLogo,
} from '@backstage/plugin-home';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import {
  // tools,
  useLogoStyles,
} from './shared';

import {
  AnnouncementsCard,
  NewAnnouncementBanner,
} from '@procore-oss/backstage-plugin-announcements';

const useStyles = makeStyles(theme => ({
  searchBarInput: {
    maxWidth: '60vw',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px',
    boxShadow: theme.shadows[1],
  },
  searchBarOutline: {
    borderStyle: 'none',
    border: '0 !important',
    // This line disable the blue border
    boxShadow: '0 !important',
    '&:hover': {
      border: '0 !important',
    },
  },
}));

export const HomePage = () => {
  const classes = useStyles();
  const { svg, path, container } = useLogoStyles();

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Header title="Portail développeur" />
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <Grid item md={12}>
              <NewAnnouncementBanner />
            </Grid>
            <Grid container item xs={12} alignItems="center" direction="column">
              <HomePageCompanyLogo
                className={container}
                logo={<TemplateBackstageLogo classes={{ svg, path }} />}
              />
            </Grid>
            <Grid container item xs={12} justifyContent="center">
              <HomePageSearchBar
                className={classes.searchBarOutline}
                InputProps={{
                  classes: {
                    root: classes.searchBarInput,
                    notchedOutline: classes.searchBarOutline,
                  },
                }}
                placeholder="Portal search"
              />
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <HomePageTopVisited />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageRecentlyVisited />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <AnnouncementsCard max={3} />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageStarredEntities />
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
