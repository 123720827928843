import React from 'react';
import { makeStyles } from '@material-ui/core';
// import AlithyaLogoFull from './AlithyaLogoIcon';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 40,
  },
  path: {
    fill: '#FFFFFF',
  },
});

export const AlithyaLogoFull = (props: any | undefined) => {
  const defaultClasses = useStyles();
  const classes = {
    ...defaultClasses,
    ...props?.classes,
  };

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      // viewBox="0 0 2079.95 456.05"
      viewBox="0 0 92.08 29"
    >
      <g id="logo">
        <g>
          <path
            className={classes.path}
            d="m78.49,18.8c0-3.48,2.47-4.64,5.58-4.86,2.41-.17,3.24-.12,4.03-.12v-1.27c0-1.28-.85-1.94-2.33-2.02-1.55-.08-3.36.07-5.77.72l1.17-3.3s1.91-.71,4.62-.71c3.5,0,6.3,1.25,6.3,5.4v10.5h-3.76v-1.1h-.12c-.62.53-2.27,1.39-4.59,1.39-3,0-5.11-1.56-5.11-4.64h0Zm9.64.69v-3.05c-.56,0-2.06-.02-3,.11-1.52.21-2.52.9-2.52,2.1s.95,1.79,2.28,1.79c1.89,0,2.49-.56,3.24-.94h0Z"
          />
          <path
            className={classes.path}
            d="m17.13,23.15h4.52L10.83,0,0,23.15h4.52l1.8-3.84h9.02l1.8,3.84h0Zm-9.02-7.68l2.72-6.31,2.72,6.31h-5.43Z"
          />
          <path className={classes.path} d="m23.19,23.15V1.85h4.1v21.3h-4.1Z" />
          <path
            className={classes.path}
            d="m30.66,1.85v4.09h4.1V1.85s-4.1,0-4.1,0Zm0,21.3h4.1V7.87h-4.1v15.28Z"
          />
          <polygon
            className={classes.path}
            points="46.55 7.87 42.25 7.87 42.25 1.85 38.15 1.85 38.15 23.15 42.25 23.15 42.25 11.73 45.19 11.73 46.55 7.87"
          />
          <path
            className={classes.path}
            d="m56.06,7.25c-1.63,0-2.94.62-3.65,1.37V1.85h-4.1v21.3h4.1v-8.52c0-2.7.98-3.53,2.67-3.53s2.64.83,2.64,3.62v8.43h4.09v-8.78c0-4.39-1.78-7.12-5.76-7.12h0Z"
          />
          <path
            className={classes.path}
            d="m74.21,7.87l-3.5,10.62-3.37-10.62h-4.31l5.48,15.72-.25.68c-.57,1.46-1.65,1.53-3.17,1.06l-1.12,3.18c.75.32,1.79.49,2.83.49,3.21,0,4.75-1.71,5.63-4.22l6.12-16.91h-4.34Z"
          />
        </g>
      </g>
    </svg>
  );
};
