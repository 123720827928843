import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 50,
  },
  path: {
    fill: '#4B009B',
    // fill: '#FFFFFF',
  },
});

export const BenevaLogoFull = (props: any | undefined) => {
  const defaultClasses = useStyles();
  const classes = {
    ...defaultClasses,
    ...props?.classes,
  };

  return (
    <svg
      className={classes.svg}
      width="100%"
      height="100%"
      viewBox="0 0 562 100"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="matrix(1,0,0,1,-4.26326e-14,-389.122)">
        <g
          id="Beneva-default"
          transform="matrix(1.31227,0,0,1.01902,-87.4844,351.893)"
        >
          <g>
            <g
              id="LetterA"
              transform="matrix(0.101605,0,0,-0.130844,67.3271,196.949)"
            >
              <path
                d="M3454,594C3369,594 3303,663 3303,751C3303,839 3369,907 3454,907C3542,907 3606,837 3606,751C3606,664 3542,594 3454,594ZM3600,1000L3600,942C3563,983 3503,1010 3440,1010C3310,1010 3190,910 3190,750C3190,591 3312,490 3439,490C3502,490 3562,517 3600,560L3600,500L3712,500L3712,1000L3600,1000"
                className={classes.path}
              />
            </g>
            <g
              id="LetterV"
              transform="matrix(0.101605,0,0,-0.130844,67.3271,196.949)"
            >
              <path
                d="M3075,1000L2947,652L2819,1000L2699,1000L2891,500L3003,500L3195,1000L3075,1000"
                className={classes.path}
              />
            </g>
            <g
              id="LetterEUpper"
              transform="matrix(0.101605,0,0,-0.130844,67.3271,196.949)"
            >
              <path
                d="M2300,807C2321,866 2376,910 2444,910C2512,910 2567,866 2588,807L2300,807ZM2444,1010C2298,1010 2184,895 2184,748C2184,735 2185,722 2187,710L2701,710C2703,722 2704,735 2704,748C2704,895 2590,1010 2444,1010"
                className={classes.path}
              />
            </g>
            <g
              id="Smile"
              transform="matrix(0.101605,0,0,-0.130844,67.3271,196.949)"
            >
              <path
                d="M2444,591C2384,591 2335,623 2307,671L2215,618C2262,540 2338,490 2444,490C2550,490 2626,540 2673,618L2581,671C2553,623 2504,591 2444,591"
                className={classes.path}
              />
            </g>
            <g
              id="LetterN"
              transform="matrix(0.101605,0,0,-0.130844,67.3271,196.949)"
            >
              <path
                d="M1917,1010C1855,1010 1804,983 1769,942L1769,1000L1657,1000L1657,500L1769,500L1769,775C1769,859 1823,911 1891,911C1967,911 2007,861 2007,782L2007,500L2121,500L2121,791C2121,927 2043,1010 1917,1010"
                className={classes.path}
              />
            </g>
            <g
              id="LetterEUpper1"
              transform="matrix(0.101605,0,0,-0.130844,67.3271,196.949)"
            >
              <path
                d="M1190,807C1211,866 1266,910 1334,910C1402,910 1457,866 1478,807L1190,807ZM1334,1010C1188,1010 1074,895 1074,748C1074,735 1075,722 1077,710L1591,710C1593,722 1594,735 1594,748C1594,895 1480,1010 1334,1010"
                className={classes.path}
              />
            </g>
            <g
              id="Smile1"
              transform="matrix(0.101605,0,0,-0.130844,67.3271,196.949)"
            >
              <path
                d="M1334,591C1274,591 1225,623 1197,671L1105,618C1152,540 1228,490 1334,490C1440,490 1516,540 1563,618L1471,671C1443,623 1394,591 1334,591"
                className={classes.path}
              />
            </g>
            <g
              id="LetterB"
              transform="matrix(0.101605,0,0,-0.130844,67.3271,196.949)"
            >
              <path
                d="M758,594C670,594 606,664 606,751C606,837 670,907 758,907C843,907 909,839 909,751C909,663 843,594 758,594ZM772,1010C709,1010 649,983 612,942L612,1226L500,1226L500,500L612,500L612,560C650,517 710,490 773,490C900,490 1022,591 1022,750C1022,910 902,1010 772,1010"
                className={classes.path}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
