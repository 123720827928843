import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 50,
  },
  path: {
    fill: '#4B009B',
  },
  beneva: {
    blue: {
      fill: '#88bae5',
    },
    green: {
      fill: '#aadc9a',
    },
    purple: {
      fill: '#3d2d92',
    },
    yellow: {
      fill: '#fbcf65',
    },
    orange: {
      fille: '#f55a47',
    },
  },
});

export const BenevaLogoIcon = (props: any | undefined) => {
  const defaultClasses = useStyles();
  const classes = {
    ...defaultClasses,
    ...props?.classes,
  };

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 210 42"
    >
      <path
        id="Protect"
        d="M201.681,27.733C193.821,27.733 187.402,23.467 183.733,17.067L171.681,24.133C177.838,34.533 187.795,41.2 201.681,41.2C215.567,41.2 225.524,34.533 231.681,24.133L219.629,17.067C215.961,23.467 209.541,27.733 201.681,27.733"
        fill="#88bae5"
      />
      <path
        id="Smile"
        d="M153.733,13.467C145.873,13.467 139.454,17.733 135.786,24.133L123.733,17.067C129.891,6.667 139.847,0 153.733,0C167.62,0 177.576,6.667 183.733,17.067L171.681,24.133C168.013,17.733 161.594,13.467 153.733,13.467"
        fill="#aadc9a"
      />
      <path
        id="Smile1"
        d="M105.786,27.733C97.926,27.733 91.506,23.467 87.838,17.067L75.786,24.133C81.943,34.533 91.899,41.2 105.786,41.2C119.672,41.2 129.629,34.533 135.786,24.133L123.733,17.067C120.065,23.467 113.646,27.733 105.786,27.733"
        fill="#2F106B"
      />
      <path
        id="Smile2"
        d="M57.838,13.467C49.978,13.467 43.559,17.733 39.891,24.133L27.838,17.067C33.995,6.667 43.952,0 57.838,0C71.725,0 81.681,6.667 87.838,17.067L75.786,24.133C72.118,17.733 65.698,13.467 57.838,13.467"
        fill="#fbcf65"
      />
      <path
        id="Protect1"
        d="M9.891,27.733C2.03,27.733 -4.389,23.467 -8.057,17.067L-20.109,24.133C-13.952,34.533 -3.996,41.2 9.891,41.2C23.777,41.2 33.733,34.533 39.891,24.133L27.838,17.067C24.17,23.467 17.751,27.733 9.891,27.733"
        fill="#f55a47"
      />
    </svg>
  );
};
