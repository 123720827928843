import {
  createBaseThemeOptions,
  pageTheme as defaultPageThemes,
  PageTheme,
  palettes,
  createUnifiedTheme,
  shapes,
  genPageTheme,
} from '@backstage/theme';

import { alpha } from '@material-ui/core/styles';

const pageThemesFontColorOverride: Record<string, PageTheme> = {};
Object.keys(defaultPageThemes).map(key => {
  pageThemesFontColorOverride[key] = {
    ...defaultPageThemes[key],
    // fontColor: '#002957',
    // backgroundImage: 'unset',
  };
});

export const alithyaTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      alithya: {
        white: '#FFFFFF',
        black: '#000000',
        marine: '#002957',
        marineDark: '#00162E',
        green: '#C8ED21',
        greenDark: '#697D0A',
        blue: '#1F96E3',
        blueDark: '#0E4B73',
        grey: '#B8C3D0',
        greyDark: '#4E6076',
      },
      primary: {
        main: '#002957',
        light: '#4C9AFF',
        dark: '#0052CC',
      },
      secondary: {
        main: '#FFFFFF',
        light: '#C8ED21',
        dark: '#1F96E3',
      },
      grey: {
        50: '#C1C7D0',
        100: '#7A869A',
        200: '#6B778C',
        300: '#5E6C84',
        400: '#505F79',
        500: '#42526E',
        600: '#344563',
        700: '#253858',
        800: '#002957',
        900: '#091E42',
      },
      error: {
        main: '#FF5630',
        light: '#FF8F73',
        dark: '#DE350B',
      },
      warning: {
        main: '#FFAB00',
        light: '#FFE380',
        dark: '#FF8B00',
      },
      success: {
        main: '#36B37E',
        light: '#79F2C0',
        dark: '#006644',
      },
      info: {
        main: '#0065FF',
        light: '#4C9AFF',
        dark: '#0747A6',
      },
      navigation: {
        ...palettes.light.navigation,
        background: '#002957',
        color: '#FFFFFF',
        indicator: '#2684FF',
        navItem: {
          hoverBackground: '#00162E',
        },
      },
      text: {
        primary: '#172B48',
      },
      background: {
        default: '#F1F3F6',
      },
    },
  }),
  fontFamily: 'Poppins, sans-serif',
  pageTheme: {
    home: genPageTheme({
      colors: ['#FFFFFF', '#FFFFFF'],
      shape: shapes.wave,
      options: { fontColor: '#002957' },
    }),
    documentation: genPageTheme({
      colors: ['#B8C3D0', '#B8C3D0'],
      shape: shapes.wave2,
      options: { fontColor: '#FFFFFF' },
    }),
    tool: genPageTheme({
      colors: ['#002957', '#002957'],
      shape: shapes.round,
      options: { fontColor: '#FFFFFF' },
    }),
    service: genPageTheme({
      colors: ['#697D0A', '#697D0A'],
      shape: shapes.wave,
      options: { fontColor: '#FFFFFF' },
    }),
    website: genPageTheme({
      colors: ['#C8ED21', '#C8ED21'],
      shape: shapes.wave,
      options: { fontColor: '#FFFFFF' },
    }),
    library: genPageTheme({
      colors: ['#1F96E3', '#1F96E3'],
      shape: shapes.wave,
      options: { fontColor: '#FFFFFF' },
    }),
    other: genPageTheme({
      colors: ['#4E6076', '#4E6076'],
      shape: shapes.wave,
      options: { fontColor: '#FFFFFF' },
    }),
    app: genPageTheme({
      colors: ['#0E4B73', '#0E4B73'],
      shape: shapes.wave,
      options: { fontColor: '#FFFFFF' },
    }),
    apis: genPageTheme({
      colors: ['#1F96E3', '#1F96E3'],
      shape: shapes.wave,
      options: { fontColor: '#FFFFFF' },
    }),
  },
  defaultPageTheme: 'home',
  components: {
    BackstageHeader: {
      styleOverrides: {
        header: ({ theme }) => ({
          // backgroundColor: '#FFFFFF',
          backgroundImage: 'unset',
          boxShadow: 'unset',
          paddingBottom: theme.spacing(3),
        }),
        title: ({ theme }) => ({
          color: theme.palette.primary.main,
          fontWeight: 100,
        }),
        subtitle: ({ theme }) => ({
          color: alpha(theme.palette.primary.main, 0.8),
        }),
        type: ({ theme }) => ({
          color: alpha(theme.palette.primary.main, 0.8),
        }),
        rightItemsBox: ({ theme }) => ({
          color: theme.palette.primary.main,
        }),
      },
    },
    BackstageHeaderTabs: {
      styleOverrides: {
        defaultTab: {
          fontSize: 'inherit',
          textTransform: 'none',
        },
      },
    },
    BackstageOpenedDropdown: {
      styleOverrides: {
        icon: {
          '& path': {
            fill: '#FFFFFF',
          },
        },
      },
    },
    BackstageTable: {
      styleOverrides: {
        root: {
          '&> :first-child': {
            borderBottom: '1px solid #D5D5D5',
            boxShadow: 'none',
          },
          '& th': {
            borderTop: 'none',
            textTransform: 'none !important',
          },
        },
      },
    },
    BackstageHeaderLabel: {
      styleOverrides: {
        label: ({ theme }) => ({
          color: theme.palette.primary.main,
        }),
        value: ({ theme }) => ({
          color: alpha(theme.palette.primary.main, 0.8),
        }),
      },
    },
    BackstageItemCardHeader: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.primary.main,
        }),
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
        standardError: ({ theme }) => ({
          color: '#FFFFFF',
          backgroundColor: theme.palette.error.dark,
          '& $icon': {
            color: '#FFFFFF',
          },
        }),
        standardInfo: ({ theme }) => ({
          color: '#FFFFFF',
          backgroundColor: theme.palette.primary.dark,
          '& $icon': {
            color: '#FFFFFF',
          },
        }),
        standardSuccess: ({ theme }) => ({
          color: '#FFFFFF',
          backgroundColor: theme.palette.success.dark,
          '& $icon': {
            color: '#FFFFFF',
          },
        }),
        standardWarning: ({ theme }) => ({
          color: theme.palette.grey[700],
          backgroundColor: theme.palette.secondary.light,
          '& $icon': {
            color: theme.palette.grey[700],
          },
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&[aria-expanded=true]': {
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.primary.main,
          },
          '&[aria-expanded=true] path': {
            fill: '#FFFFFF',
          },
        }),
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: alpha(theme.palette.primary.main, 0.8),
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: 3,
          backgroundColor: theme.palette.grey[50],
          color: theme.palette.primary.dark,
          margin: 4,
        }),
      },
    },
    // TODO: (awanlin) - we get a type error for `root`, need to investigate
    MuiSelect: {
      styleOverrides: {
        select: ({ theme }) => ({
          '&[aria-expanded]': {
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.primary.main,
          },
        }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 10,
        },
        switchBase: {
          padding: 12,
        },
        thumb: {
          backgroundColor: '#FFFFFF',
          height: 14,
          width: 14,
        },
        track: {
          borderRadius: 9,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          transition: 'none',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        button: {
          textTransform: 'none',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.primary.main,
        }),
      },
    },
    BackstageSelectInputBase: {
      styleOverrides: {
        // .MuiSelect-select-5151.MuiSelect-select-5151
        root: {
          // color: "#6272A4",
          '&.Mui-focused > div[role=button]': {
            borderColor: '#F8F8F2',
          },
        },
        input: {
          border: '1px solid #6272A4',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected, &.Mui-selected:hover': {
            // backgroundColor: '#44475A',
          },
        },
        divider: {
          borderBottom: '1px solid #6272A4',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: '#44475A',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        colorPrimary: ({ theme }) => ({
          color: theme.palette.primary.light,
        }),
      },
    },
    BackstageIconLinkVertical: {
      styleOverrides: {
        primary: ({ theme }) => ({
          color: theme.palette.primary.light,
        }),
      },
    },
    // CatalogReactEntityDisplayName: {
    //   styleOverrides: {
    //     root: ({ theme }) => ({
    //       color: theme.palette.secondary.light,
    //     }),
    //   }
    // },
    PluginCatalogEntityContextMenu: {
      styleOverrides: {
        button: ({ theme }) => ({
          color: theme.palette.primary.main,
        }),
      },
    },
  },
});
