import {
  graphQlBrowseApiRef,
  GraphQLEndpoints,
} from '@backstage-community/plugin-graphiql';
import {
  ScmAuth,
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
} from '@backstage/integration-react';

import {
  createApiFactory,
  githubAuthApiRef,
  gitlabAuthApiRef,
  discoveryApiRef,
  oauthRequestApiRef,
  errorApiRef,
  configApiRef,
  AnyApiFactory,
  identityApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';

import { GithubAuth } from '@backstage/core-app-api';
import { visitsApiRef, VisitsWebStorageApi } from '@backstage/plugin-home';
import { ragAiApiRef, RoadieRagAiClient } from '@alithya-oss/plugin-rag-ai';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),
  createApiFactory({
    api: githubAuthApiRef,
    deps: {
      configApi: configApiRef,
      discoveryApi: discoveryApiRef,
      oauthRequestApi: oauthRequestApiRef,
    },
    factory: ({ discoveryApi, oauthRequestApi, configApi }) =>
      GithubAuth.create({
        discoveryApi,
        oauthRequestApi,
        defaultScopes: ['read:user'],
        environment: configApi.getString('auth.environment'),
      }),
  }),
  createApiFactory({
    api: ragAiApiRef,
    deps: {
      configApi: configApiRef,
      discoveryApi: discoveryApiRef,
      fetchApi: fetchApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ discoveryApi, fetchApi, configApi, identityApi }) => {
      return new RoadieRagAiClient({
        discoveryApi,
        fetchApi,
        configApi,
        identityApi,
      });
    },
  }),
  createApiFactory({
    api: graphQlBrowseApiRef,
    deps: {
      errorApi: errorApiRef,
      githubAuthApi: githubAuthApiRef,
      gitlabAuthApi: gitlabAuthApiRef,
      discoveryApi: discoveryApiRef,
      identityApi: identityApiRef,
      fetchApi: fetchApiRef,
      oauthRequestApi: oauthRequestApiRef,
    },
    factory: ({
      errorApi,
      githubAuthApi,
      gitlabAuthApi,
      discoveryApi,
      fetchApi,
      identityApi,
    }) =>
      GraphQLEndpoints.from([
        {
          id: 'backstage-graphql',
          title: 'Backstage',
          // we use the lower level object with a fetcher function
          // as we need to `await` the backend url for the graphql plugin
          fetcher: async (params: any) => {
            const { token } = await identityApi.getCredentials();
            const graphqlURL = await discoveryApi.getBaseUrl('graphql');
            return fetch(graphqlURL, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(params),
            }).then(res => res.json());
          },
        },
        GraphQLEndpoints.github({
          id: 'github-graphql',
          title: 'Github',
          errorApi: errorApi,
          fetchApi: fetchApi,
          githubAuthApi: githubAuthApi,
        }),
        {
          id: 'gitlab-graphql',
          title: 'Gitlab',
          // we use the lower level object with a fetcher function
          // as we need to `await` the backend url for the graphql plugin
          fetcher: async (params: any) => {
            const token = await gitlabAuthApi.getAccessToken([
              'read_user',
              'read_api',
              'read_repository',
            ]);
            const graphqlURL = `https://gitlab.com/api/graphql?access_token=${token}`;
            // const idToken = await gitlabAuthApi.getIdToken();
            return fetch(graphqlURL, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(params),
            }).then(res => res.json());
          },
        },
      ]),
  }),
  createApiFactory({
    api: visitsApiRef,
    deps: {
      identityApi: identityApiRef,
      errorApi: errorApiRef,
    },
    factory: ({ identityApi, errorApi }) =>
      VisitsWebStorageApi.create({ identityApi, errorApi }),
  }),
];
